$(document).ready(function () {
    $('.form-validate').validate();

    $.validator.addMethod( "cpfBR", function( value ) {
        if(value == ''){
            return true;
        }

        // Removing special characters from value
        value = value.replace( /([~!@#$%^&*()_+=`{}\[\]\-|\\:;'<>,.\/? ])+/g, "" );

        // Checking value to have 11 digits only
        if ( value.length !== 11 ) {
            return false;
        }

        var sum = 0,
            firstCN, secondCN, checkResult, i;

        firstCN = parseInt( value.substring( 9, 10 ), 10 );
        secondCN = parseInt( value.substring( 10, 11 ), 10 );

        checkResult = function( sum, cn ) {
            var result = ( sum * 10 ) % 11;
            if ( ( result === 10 ) || ( result === 11 ) ) {
                result = 0;
            }
            return ( result === cn );
        };

        // Checking for dump data
        if ( value === "" ||
            value === "00000000000" ||
            value === "11111111111" ||
            value === "22222222222" ||
            value === "33333333333" ||
            value === "44444444444" ||
            value === "55555555555" ||
            value === "66666666666" ||
            value === "77777777777" ||
            value === "88888888888" ||
            value === "99999999999"
        ) {
            return false;
        }

        // Step 1 - using first Check Number:
        for ( i = 1; i <= 9; i++ ) {
            sum = sum + parseInt( value.substring( i - 1, i ), 10 ) * ( 11 - i );
        }

        // If first Check Number (CN) is valid, move to Step 2 - using second Check Number:
        if ( checkResult( sum, firstCN ) ) {
            sum = 0;
            for ( i = 1; i <= 10; i++ ) {
                sum = sum + parseInt( value.substring( i - 1, i ), 10 ) * ( 12 - i );
            }
            return checkResult( sum, secondCN );
        }
        return false;

    }, "CPF inválido" );

    $.extend( $.validator.messages, {
        required: "Este campo &eacute; obrigat&oacute;rio."
    });

    masks();
});

function masks(){
    var phoneMask = function (phone, e, currentField, options) {
        if (phone.length < 15) {
            return '(00) 0000-00009';
        }
        return '(00) 00000-0009';
    };

    $(".mask-phone").mask(phoneMask, {
        onKeyPress: function (phone, e, currentField, options) {
            $(currentField).mask(phoneMask(phone), options);
        }
    });

    $(".mask-cpf").mask("000.000.000-00");
    $('.select2').select2();
    $('[data-toggle="tooltip"]').tooltip();
}

function showTrailer(trailer){
    if (trailer.indexOf("http") < 0){
        trailer = 'http://'+trailer
    }

    $('#content-trailer').attr('src', trailer);
    $('#show-trailer').modal('show');
}

function showSessions(id,element){
    $('.day.active').removeClass('active');
    $('.session.active').removeClass('active');
    $('#'+id).hide();
    $(element).hide();

    $('#'+id).addClass('active');
    $(element).addClass('active');

    $('#'+id).fadeIn();
    $(element).fadeIn();
}

$('#show-trailer').on('hidden.bs.modal', function (e) {
    $('#content-trailer').attr('src', "");
})

$('.map').click(function () {
    $('.map iframe').css("pointer-events", "auto");
});

$('.map' ).mouseleave(function() {
    $('.map iframe').css("pointer-events", "none");
});


var verifyCallback = function(response) {
    check_captcha = true;
    $('#captcha-message').remove();
};

var onloadCallback = function() {
    grecaptcha.render('captcha', {
        'sitekey' : '6Lcj3h8TAAAAAN__brxcmJzpKXtjydFms3Vr4RnU',
        'callback' : verifyCallback
    });
};

function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function filterStores() {
    $('#search-segments').select2({placeholder: "Busque por segmento"});
    $('#search-store').select2({placeholder: "Busque por loja"});
}

var nextImg;
var previousImg;

function showImage(previous, element, next) {
    nextImg = next;
    previousImg = previous;

    $('#show-image img').attr('src',element);
    $('#show-image').modal('show');
}

function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

window.scrollPage = function (id) {
    var headerHeight = $('.navbar.menu').outerHeight() + 200;

    if(id === 'register-container') {
        headerHeight = headerHeight + 50;
    }

    $('html,body').animate({ scrollTop: $("#" + id).offset().top - headerHeight }, 'slow');
};
